import Toggle from "./toggle";

const Row = ({ cls, obj }) => {
    return <div className={cls}>


        <Toggle title={<div className="heading flex-sb-st"><h3 className="grid_5">{obj.Name}</h3><p className="grid_7">{obj.Suburb}, {obj.StateAbbr}</p></div>}>
            <div className="flex-en-st">
                <div className="grid_7 flex-st-st gap">
                    <div className="grid_5">
                    <img className="img mb20" src={obj.ProjectImage}/>
                    </div>
                    <div className="grid_6">
                    {obj.Granline && <div className="mb15"> <p>{obj.Granline}</p></div>}
                    <div className="p-mb" dangerouslySetInnerHTML={{ __html: obj.Description }}></div>
                    {obj.ProjectValue && <div className="mb15"> <p className="mb5 uppercase">PROJECT VALUE</p><p>{obj.ProjectValue}</p></div>}
                    {obj.CurrentTenants && <div className="mb15"> <p className="mb5 uppercase">Current Tenants</p><p>{obj.CurrentTenants}</p></div>}
                    {/* {obj.ProjectSizeType && <div className="mb15"> <p className="mb5 uppercase">Size / Type </p><p>{obj.ProjectSizeType}</p></div>} */}
                    {obj.completed && <div className="mb15"> <p className="mb5 uppercase">completed </p><p>{obj.completed}</p></div>}
                    </div>
                </div>
            </div>
        </Toggle>

    </div>;
}

export default Row;