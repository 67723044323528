const Partners = ({content,logos}) => {

    return <>
    <section id="partners" className="ptxl pbxl">
      <div className="container">
      <h2 className="mb30">Our Partners</h2>
      <div className="p-mb max520 mb60"   dangerouslySetInnerHTML={{__html: content.copy3}}></div>               
      </div>
      <div className="container logos pbxl">
        {
          logos.map((l,index)=><p key={index} className="p-logo" style={{backgroundImage:`url(${l})`}}>
          
          </p>)
        }

      </div>
    
  </section>
 
</>;
}



export default Partners;