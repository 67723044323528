export const scroll = () => {
    let lastScrollTop = 0;
    let logoChange = false
    const logo = document.getElementById('logo')
    const sections = document.querySelectorAll('section')
    const breaks = document.querySelectorAll('.break')
    const links = document.querySelectorAll('.link')
    const body = document.body
    const ancher = document.getElementById('ancher')
    const rate = 400

    const handleChange = (cls) => {

        links.forEach(l => {
            if (l.classList.contains(cls))
                l.classList.add('active-page')
            else
                l.classList.remove('active-page')

        })
    }

    let ticking = false
    const checkElements = () => {
        const t = ancher.getBoundingClientRect().top;
        const m = (0.85 * t) / window.innerHeight //was 1.44
        const w = window.innerWidth      
   
        if (t <= 0) {
            logo.style.width = rate+'px';
            if(!logo.classList.contains('faded'))  
                logo.classList.add('faded')
        
        }
        else if(t>0)
        {
            logo.style.width = (m * w) + rate + 'px'; 
            if(logo.classList.contains('faded'))       
                logo.classList.remove('faded')
            
        }


        if (!logoChange && logo.getBoundingClientRect().bottom < 0) {
            logoChange = true
            //  smlLogo.style.opacity=1
        }
        else if (logoChange && logoChange && logo.getBoundingClientRect().bottom >= 0) {
            logoChange = false
            // smlLogo.style.opacity=0
        }
        breaks.forEach((br, index) => {
            const top = br.getBoundingClientRect().top,
                bottom = br.getBoundingClientRect().bottom;

            if (top <= window.innerHeight && bottom > window.innerHeight) {
                const cls = br.dataset.cls
                if (!body.classList.contains(cls)) {
                    if (cls === "bg-white") {
                        body.classList.remove('bg-blue')
                    }
                    else
                        body.classList.remove('bg-white')
                    body.classList.add(cls)
                }


            }

        })
        sections.forEach(sec => {
            const top = sec.getBoundingClientRect().top,
                bottom = sec.getBoundingClientRect().bottom;

            if (top > 0 && bottom < window.innerHeight && sec.dataset.cls && !body.classList.contains(sec.dataset.cls)) {
                body.classList.add(sec.dataset.cls)
            }

            if (top <= 3 && bottom > 0 && !sec.classList.contains('active-page')) {
                handleChange(sec.getAttribute('id'))
            }

        })
    }
    document.addEventListener("scroll", (event) => {

        if (!ticking) {
            window.requestAnimationFrame(() => {

                checkElements();
                lastScrollTop = window.scrollY
                ticking = false;
            });

            ticking = true;
        }
    });
}