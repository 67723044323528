
const Logo = () => {
    return <a href="/" className="logo" id="logo">
<svg width="1256" height="134" viewBox="0 0 1256 134" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 3.53996V1.66975H33.3075L63.4535 97.1172H64.1883L93.7776 1.66975H127.642V3.53996L81.6881 131.917H46.5326L0 3.53996Z" fill="#272635"/>
<path d="M142.515 1.66975H235.536V27.7191H171.726V51.7201H229.769V77.7695H171.726V106.045H235.536V131.917H142.515V1.66975Z" fill="#272635"/>
<path d="M365.779 130.046V131.917H331.537L303.439 85.9628H285.204V131.917H255.993V1.66975H315.528C343.248 1.66975 359.99 21.0175 359.99 43.1706C359.99 61.9618 350.127 76.0996 334.32 82.4227L365.757 130.046H365.779ZM285.227 59.9134H314.059C325.77 59.9134 330.067 51.542 330.067 43.3487C330.067 35.1554 325.414 27.7191 314.059 27.7191H285.227V59.9134Z" fill="#272635"/>
<path d="M380.656 1.66975H415.077L463.079 86.6975H463.814V1.66975H492.847V131.917H458.248L410.246 47.4454H409.511V131.917H380.679V1.66975H380.656Z" fill="#272635"/>
<path d="M634.6 130.046V131.917H602.227L594.591 107.181H546.21L538.573 131.917H507.693V130.046L552.377 1.66975H589.781L634.622 130.046H634.6ZM586.598 81.4876L570.79 30.8806H570.055L554.247 81.4876H586.62H586.598Z" fill="#272635"/>
<path d="M640.19 66.9713C640.19 29.389 661.408 0 703.265 0C736.573 0 759.082 23.2663 760.952 50.7851H731.185C730.072 38.317 721.5 26.4056 703.265 26.4056C681.691 26.4056 669.958 40.7216 669.958 66.9713C669.958 93.221 681.669 107.337 703.265 107.337C720.765 107.337 729.136 96.3603 731.363 84.0704H760.952C758.347 110.854 736.395 133.564 703.265 133.564C661.408 133.564 640.19 103.975 640.19 66.9491V66.9713Z" fill="#272635"/>
<path d="M777.674 80.3744V1.66975H806.885V79.9959C806.885 94.5123 812.273 107.537 832.756 107.537C853.239 107.537 858.805 94.5123 858.805 79.9959V1.66975H888.194V80.3744C888.194 115.908 863.815 133.586 832.756 133.586C801.697 133.586 777.696 115.908 777.696 80.3744H777.674Z" fill="#272635"/>
<path d="M912.373 1.66975H941.584V105.867H1000.74V131.917H912.373V1.66975Z" fill="#272635"/>
<path d="M1131.36 130.046V131.917H1098.99L1091.35 107.181H1042.97L1035.34 131.917H1004.46V130.046L1049.12 1.66975H1086.52L1131.36 130.046ZM1083.36 81.4876L1067.55 30.8806H1066.82L1051.01 81.4876H1083.38H1083.36Z" fill="#272635"/>
<path d="M1256 130.046V131.917H1221.76L1193.66 85.9628H1175.43V131.917H1146.21V1.66975H1205.75C1233.47 1.66975 1250.21 21.0175 1250.21 43.1706C1250.21 61.9618 1240.35 76.0996 1224.54 82.4227L1255.98 130.046H1256ZM1175.45 59.9134H1204.28C1215.99 59.9134 1220.29 51.542 1220.29 43.3487C1220.29 35.1554 1215.63 27.7191 1204.28 27.7191H1175.45V59.9134Z" fill="#272635"/>
</svg>
{/* <svg className="abs-svg"  width="240" height="15" viewBox="0 0 240 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path  className="toFade" d="M138.563 0.172852H144.77C147.563 0.172852 149.277 2.14387 149.277 4.68003C149.277 7.2162 147.521 9.26661 144.77 9.26661H141.627V13.8345H138.563V0.172852ZM144.163 6.53428C145.471 6.53428 146.153 5.93177 146.153 4.68003C146.153 3.5077 145.41 2.8865 144.163 2.8865H141.627V6.53428H144.163Z" fill="#272635"/>
<path   className="toFade"d="M162.739 13.6383V13.8345H159.147L156.2 9.0144H154.289V13.8345H151.225V0.172852H157.47C160.38 0.172852 162.136 2.20459 162.136 4.5259C162.136 6.49692 161.104 7.98218 159.441 8.64542L162.739 13.643V13.6383ZM154.289 6.28207H157.316C158.544 6.28207 158.993 5.40398 158.993 4.54458C158.993 3.68518 158.507 2.90518 157.316 2.90518H154.289V6.28207Z" fill="#272635"/>
<path  className="toFade" d="M163.523 7.00598C163.523 3.08263 165.746 0 170.16 0C174.574 0 176.797 3.08263 176.797 7.00598C176.797 10.9293 174.555 14.012 170.16 14.012C165.765 14.012 163.523 10.9106 163.523 7.00598ZM173.672 7.00598C173.672 4.25497 172.425 2.7697 170.16 2.7697C167.895 2.7697 166.648 4.25497 166.648 7.00598C166.648 9.757 167.895 11.2423 170.16 11.2423C172.425 11.2423 173.672 9.757 173.672 7.00598Z" fill="#272635"/>
<path  className="toFade" d="M178.739 0.172852H184.947C187.74 0.172852 189.454 2.14387 189.454 4.68003C189.454 7.2162 187.698 9.26661 184.947 9.26661H181.803V13.8345H178.739V0.172852ZM184.339 6.53428C185.647 6.53428 186.329 5.93177 186.329 4.68003C186.329 3.5077 185.586 2.8865 184.339 2.8865H181.803V6.53428H184.339Z" fill="#272635"/>
<path  className="toFade" d="M191.406 0.172852H201.163V2.90518H194.47V5.42267H200.561V8.155H194.47V11.1209H201.163V13.8345H191.406V0.172852Z" fill="#272635"/>
<path   className="toFade"d="M214.825 13.6383V13.8345H211.233L208.286 9.0144H206.376V13.8345H203.312V0.172852H209.557C212.466 0.172852 214.223 2.20459 214.223 4.5259C214.223 6.49692 213.19 7.98218 211.528 8.64542L214.825 13.643V13.6383ZM206.376 6.28207H209.403C210.631 6.28207 211.079 5.40398 211.079 4.54458C211.079 3.68518 210.594 2.90518 209.403 2.90518H206.376V6.28207Z" fill="#272635"/>
<path  className="toFade" d="M219.253 2.92851H215.311V0.17749H226.277V2.92851H222.335V13.8392H219.253V2.92851Z" fill="#272635"/>
<path className="toFade" d="M231.859 8.41185L226.763 0.373657V0.17749H230.336L233.382 5.32922H233.461L236.427 0.17749H240V0.373657L234.946 8.37449V13.8392H231.864V8.41185H231.859Z" fill="#272635"/>
</svg> */}
    </a>;
}

export default Logo;