import Toggle from "./toggle";

const Grid = ({cls,obj}) => {
    return <div className={cls}>
    <img className="img mb20" src={obj.ProjectImage}/>
    <h3 className="mb5">{obj.Name}, {obj.StateAbbr}</h3>
    <p className="mb30">{obj.address}</p>
    <Toggle  open={true} title={<p className="heading">{obj.ProjectSizeType}</p>}>

    <div className="p-mb" dangerouslySetInnerHTML={{__html: obj.Description}}></div>
    {obj.ProjectValue && <div className="mb15"> <p className="mb5 uppercase">PROJECT VALUE</p><p>{obj.ProjectValue}</p></div>}
    {/* {obj.ProjectSizeType && <div className="mb15"> <p className="mb5 uppercase">Size / Type </p><p>{obj.ProjectSizeType}</p></div>} */}
    {obj.Completed && <div className="mb15"> <p className="mb5 uppercase">Completion Date </p><p>{obj.Completed}</p></div>}
    {obj.CurrentTenants && <div className="mb15"> <p className="mb5 uppercase">Current Tenants</p><p>{obj.CurrentTenants}</p></div>}
    
        </Toggle>

    </div>;
}

export default Grid;