const About = ({content}) => {
    return <section id="about" className="ptxl pbxl">
        <div className="container ">
        <h2 className="mb30 max10 " dangerouslySetInnerHTML={{__html: content.heading1}}/>   
           
        </div>
        <div className="container flex-sb-st">
            <div className="grid_5">
            <div className="p-mb max520" dangerouslySetInnerHTML={{__html: content.copy1}}></div>
                {/* <p className="mb15 max520">Vernacular is an Australian commercial development property company  with a track record of over 15 years of industry experience.</p>
                <p className="mb15 max520">Our primary focus is on conceptualising and delivering real estate projects that not only meet the immediate needs of our tenants but also partners and investors. Our commitment to excellence has led us to success in diverse sectors, including retail, commercial, and mixed-use properties. </p> */}
            </div>
            <div className="grid_7">
                <img src={content.image1}className="img"/>
            </div>

        </div>
        {/* <span className="bg"></span> */}
    
        </section>;
}
export default About;