
import { useState, useEffect ,useRef} from 'react';
import Header from './components/header';
import Home from './components/home';
import About from './components/about';
import Projects from './components/projects';
import Partners from './components/partners';
import Contact from './components/contact';
import Footer from './components/footer';
import { scroll } from './fns/scroll';
function App() {
   const [projects, setProjects] = useState([])
   const [homeContent, setHomeContent] = useState({})
   const [contactConent, setContactContent] = useState(null)
   const [logos, setLogos] = useState(null)
   const [privacy, setPrivacy] = useState(null)
   const refLoader=useRef()
  
  const fetchData = async () => {
    try {
      const [_projects, _homeContent, _contactContent, logos, privacy] = await Promise.all([       
        fetch(`${process.env.REACT_APP_API}projects`),
        fetch(`${process.env.REACT_APP_API}content/home`),
        fetch(`${process.env.REACT_APP_API}content/contact`),
        fetch(`${process.env.REACT_APP_API}logos`),
        fetch(`${process.env.REACT_APP_API}content/privacy`),
      ]);
  
      const [projectsJson, homeJson, contactJson, logosJson, privacyJson] = await Promise.all([
        _projects.json(),
        _homeContent.json(),
        _contactContent.json(),
        logos.json(),
        privacy.json(),
      ]);   
      setProjects(projectsJson);
      setHomeContent(homeJson);
      setContactContent(contactJson);
      setLogos(logosJson);
      setPrivacy(privacyJson);

    } catch (error) {
      // Handle the error (e.g., log it or show a user-friendly message)
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {     
    fetchData()


  }, [])
  
  useEffect(() => {    
    if(projects.length>0)
    {
      refLoader.current.style.opacity=0;
      setTimeout(()=>{  refLoader.current.style.display='none';},1001)
      scroll()
    }


   }, [projects])
  return (
    <>
    <div id="pre-loader" ref={refLoader} style={{position:'fixed',top:0,left:0,width:'100%',height:'100%',background:'#fff',zIndex:50,transition:'opacity 1s'}}></div>
      <Header />
      {projects.length>0 &&
      <>
     <div className='break' data-cls="bg-white">
      <Home content={homeContent} />
      <About  content={homeContent}/>
      </div>     
      <div className='break' data-cls="bg-blue">
      <Projects projects={projects} content={homeContent} />
      </div>
      <div className='break' data-cls="bg-white">
      <Partners content={homeContent} logos={logos} />
      </div>
      <div className='break'  data-cls="bg-blue">
      <Contact content={contactConent} />
      </div>
      </>
}
      <Footer privacy={privacy}/>
    </>


  );
}

export default App;
