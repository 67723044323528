
import Slider from "react-slick";
const Home = ({ content }) => { 

    return <>
        <section id="home" className="pt50v">
         <div className="container flex-en-st trn-m" id="ancher">
            <div className=" abs-grid">
            <h3 className="mb30 clamp" dangerouslySetInnerHTML={{__html: content.title}}/>         
            <h1 className="mb30 " dangerouslySetInnerHTML={{__html: content.sub_title}}/>     
              
            </div>
         <div className="grid_7">
         <Slider
         autoplay={ true}
         autoplaySpeed={2500}
                   initialSlide={0}
                    dots={true}  
                    fade={true}
                
                >{content.carousel.map((img, index) => 
                    <div key={index}>                    
                        <img src={process.env.PUBLIC_URL + img} alt='vernacular' />
                    </div>)}
               
                </Slider>
         </div>
         </div>
        </section>

    </>;
}
export default Home;