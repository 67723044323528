
import Form from './form'

const Contact = ({content}) => {
    // const size=Array(15).fill(0)

       

    return <>
        <section id="contact" className="  pbl">

        <div className="container over-hidden">
            <div className='mb60'>
            <h1 className=''> {content.heading1} </h1>   
          
            </div>         
    
                <div className='flex-sb-st'>
                <div className='max430'>
                <div className="p-mb max520" dangerouslySetInnerHTML={{__html: content.grabline}}></div>
            <Form/>
            </div>
            {/* <div className='grid_5 pt-mob'>
               <div className="p-mb max520" dangerouslySetInnerHTML={{__html: content.copy1}}></div>
                <a href="mainto:info@vernacular.com.au">info@vernacular.com.au</a>
            </div> */}
          
            </div>
            </div>
        </section>

    </>;
}



export default Contact;