
import { useRef, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha";
const Form = () => {
    const recaptchaRef = useRef()
    const refName = useRef()
    const refFamily = useRef()
    const refEmail = useRef()
    const refPhone = useRef()
    const refMessage = useRef();
    const [errName, setErrName] = useState(null)
    const [errFamily, setErrFamily] = useState(null)
    const [errPhone, setErrPhone] = useState(null)
    const [errEmail, setErrEmail] = useState(null)
    const [errRec, setErrRec] = useState(null)
    const [state, setState] = useState('NOSUBMIED')

    const isEmail = (email) =>
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    const handlekeyUp = (e) => {
        const c = e.target.value
        e.target.value = c.replace(/\D/g, '')
    }
    const handleSubmit = () => {
        const recaptchaValue = recaptchaRef.current.getValue();
        
        let isValid = true
        if (refName.current.value.length < 1) {
            setErrName('Please enter first name')
            isValid = false
        }
        else setErrName(true)
        if (refFamily.current.value.length < 1) {
            setErrFamily('Please enter last name')
            isValid = false
        }
        else setErrFamily(null)

        if (!isEmail(refEmail.current.value)) {
            setErrEmail('Please enter a valid email')
            isValid = false
        }
        else setErrEmail(null)

        if (refPhone.current.value.length < 8 || refPhone.current.value.length > 14) {
            setErrPhone('Please enter a valid phone')
            isValid = false
        }
        else setErrPhone(null)
        if(recaptchaValue.length <1)
        {
        setErrRec('Please verify that you are not robot')
        isValid = false    
        }
        else
        setErrRec(null)
        if (isValid) {
            setState('FETCHING')
            fetch(process.env.REACT_APP_API + 'enquiry/leads', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                mode: 'cors',
                body: `{
                "firstName": "${refName.current.value}",
                "lastName" : "${refFamily.current.value}",
                "emailAddress" :"${refEmail.current.value}",
                "phone" : "${refPhone.current.value}",
                "comments" : "${refMessage.current.value}",
                "recaptcha":"${recaptchaValue}"


            }`

            })
                .then(response => response.json())
                .then(data => {
                
                    if (data === 'Success') {
                        setState('SUBMITED')

                    }
                    else
                    {
                        setState('ERROR') 
                    }
                })
                .catch(error => console.error('Error:', error));
        }

    }
    return <>

        {(state === 'NOSUBMIED' || state === 'FETCHING' )  &&
        <>    
         <div className="form">
    
            <input className="input" ref={refName} type="text" placeholder="First name*"></input>
            {errName && <p className="err-msg">{errName}</p>}
            <input className="input" ref={refFamily} type="text" placeholder="Last name*"></input>
            {errFamily && <p className="err-msg">{errFamily}</p>}
            <input className="input" ref={refEmail} type="email" placeholder="Email*"></input>
            {errEmail && <p className="err-msg">{errEmail}</p>}
            <input className="input" ref={refPhone} type="tel" name="phone" onKeyUp={(e) => handlekeyUp(e)} placeholder="Phone*"></input>
            {errPhone && <p className="err-msg">{errPhone}</p>}
            <textarea className="input" ref={refMessage} rows='4' placeholder="message"></textarea>
 <div className="mb20">
            <ReCAPTCHA ref={recaptchaRef}
    sitekey="6LclhognAAAAAJzC95k79ioDNYSLYgMs-XJ_xXwq"

  />
          {errRec && <p className="err-msg">{errRec}</p>}
  
  </div>
            <button className="btn" onClick={() => handleSubmit()}>SUBMIT</button>          
       
            {state === 'FETCHING' && <div className="loader">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
                </div>
                }
            </div>
  
            </>}
    
        {state === 'SUBMITED' && <div>
            <h3 className="mb15 ptm">Thank you for getting in touch with us.</h3>
            <p className="pbm">One of our friendly staff will get back to you shortly. </p>
            </div>}
        {state === 'ERROR' && <div><h4 className="red">There was an issue processing your request. Please try again later.</h4></div>}

    </>;
}



export default Form;