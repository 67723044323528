import { useRef } from 'react';
import { ScrollTo } from '../fns/scrollTo';
import Logo from './logo';

const Header = () => {
  const refNav = useRef();
  const refToggle = useRef();
  const closeNav=()=>
  { 
       refToggle.current.classList.remove('active');
  refNav.current.classList.remove('active');
  }
  const openNav=()=>
  { 
    refToggle.current.classList.add('active');
    refNav.current.classList.add('active');

  }
  const handleToggle = (e) => {
    e.preventDefault();

    if (refToggle.current.classList.contains('active')) {
        closeNav()
    } else {
        openNav()
    }
  };
  const handleClick=(e)=>
  {
    e.preventDefault();
    closeNav()
    ScrollTo(e, { duration: 1000 })
  }

  return (
    <>
      <header>
        <Logo/> 
        <a  ref={refToggle} id="nav-toggle" onClick={(e) => handleToggle(e)}>
          <span></span>
        </a>
        <nav id="nav" ref={refNav}>
          <a href="home" className="link home" onClick={(e) => handleClick(e, { duration: 1000 })}>
            Home
          </a>
          <a href="about" className="link about" onClick={(e) => handleClick(e, { duration: 1000 })}>
            About
          </a>
          <a href="projects" className="link projects" onClick={(e) => handleClick(e, { duration: 1000 })}>
            Projects
          </a>
          <a href="partners" className="link partners" onClick={(e) => handleClick(e, { duration: 1000 })}>
            Partners
          </a>
          <a href="contact" className="link contact" onClick={(e) => handleClick(e, { duration: 1000 })}>
            Contact
          </a>
        </nav>
      </header>
    </>
  );
};

export default Header;
