import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css'
import './css/slick.css'
import App from './App';
import ReactGA from "react-ga4";
ReactGA.initialize("G-GQ4QFNLEPD",{
    gaOptions: {
        cookieFlags: 'SameSite=None;Secure'
    }

});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />


);

