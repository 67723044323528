import { useRef } from "react";

const Toggle = ({ title, children,open }) => {
    const refToggle=useRef()
    const refSlide=useRef()
    const refParent=useRef()
    const refTitle=useRef()
    const handleToggle=(e)=>{
        const h=refToggle.current.clientHeight;
    
        refSlide.current.style.height=`${h}px`
        if(refToggle.current.classList.contains('active'))
        {    refParent.current.classList.remove('active')
            refToggle.current.classList.remove('active')
            refTitle.current.classList.remove('active')
            setTimeout(()=>{
                refSlide.current.style.height=`0`
            },1)
         
        }
        else
        {
            refParent.current.classList.add('active')
            refToggle.current.classList.add('active')
            refTitle.current.classList.add('active')
            setTimeout(()=>{
                refSlide.current.style.height=`auto`
            },400)

        }

    }
    return <div className={open?'toggle-holder active':'toggle-holder'}  ref={refParent}>
        <div  className={open?'toggle-title active':'toggle-title'} ref={refTitle}>
      {title}<a className="arrow middle-h" onClick={(e)=>handleToggle(e)}><img className="" src="/images/buttons/arrow.svg"/></a>
      </div>
        <div className="inner-toogle" ref={refSlide} style={open?{height:'auto'}:{height:'0'}}>
            <div  className={open?' active':''} ref={refToggle}>
            {children}
            </div>
        </div>
        <span class="spc"></span>
    </div>;
}


export default Toggle;