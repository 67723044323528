import { useEffect } from "react";
import Grid from "./grid";
import Row from "./row";



const Projects = ({ projects ,content}) => {
    
    return <>
        <section id="projects"  >
            <div className="container ">
                <h2 className="mb30">Our Projects</h2>
                
                <div className="p-mb max520 mb60"   dangerouslySetInnerHTML={{__html: content.copy2}}></div>
                <h4 className="mb30" >Current PROJECTS  - under construction </h4>
            </div>
            <div className="container flex-st-st mb80">
                {
                    projects.filter(s => s.Status=='Current').map(prj =>
                        <Grid cls={'grid_5 pr-g'} obj={prj}  />)
                }

            </div>
            <div className="container">     <h4 className="mb30">PAST PROJECTS</h4>      </div>
            <div className="container flex-st-st ">
                {
                    projects.filter(s => s.Status!='Current').map(prj =>
                        <Row cls={'pr-row'} obj={prj}  />)
                }

            </div>
            <div className="container ptxl full-m">
                {/* <img className="w58 " src="/images/projects/4.jpg" /> */}
            </div>

           {/* <span className="bg-b"></span> */}
       
        </section>


    </>;
}



export default Projects;