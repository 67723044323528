
import { useRef } from "react";
import Privacy from "./privacy";


const Footer = ({privacy}) => {

    const refPrivacy = useRef()
    const openModal = (ref) => {
        ref.current.style.display = 'block'
        setTimeout(() => {
            ref.current.style.opacity = '1'
        }, 0)

    }
    const closeModal = (ref) => {
        ref.current.style.opacity = '0'

        setTimeout(() => {
            ref.current.style.display = 'none'
        }, 700)

    }


    return <><footer className="bg-blue">
        <div className="container border-b flex-sb-st">

            <div className="p-dis">
                <p>© 2024 Vernacuar Property</p>
                <a onClick={() => openModal(refPrivacy)}>Privacy policy</a>
            </div>
            <div className="grid_5">
                <a href="https://thepropertyagency.com.au/" target="_blanck">
                Website by The Property Agency

                    </a>


              
            </div>
        </div>

    </footer>

        <div className="toPop" ref={refPrivacy}>
            <a className="close" onClick={() => closeModal(refPrivacy)}><svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path fill="#000" d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"></path></svg></a>
            <div className="inner-pop">
                <Privacy privacy={privacy} />
            </div>
        </div>
    </>
}



export default Footer;