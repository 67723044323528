export const ScrollTo=(e, options)=> {
    e.preventDefault();

    var start = window.pageYOffset,
               opt = {
                   duration: options.duration || 500,
                   offset: options.offset || 0,
                   callback: function () { },
                   easing: options.easing || easeInOutQuad
               },
               distance = document.getElementById(e.target.getAttribute('href')).getBoundingClientRect().top ,
               duration = typeof opt.duration === 'function' ? opt.duration(distance) : opt.duration,
               timeStart, timeElapsed;
    requestAnimationFrame(function (time) {
        timeStart = time;
        loop(time);
    });

    function loop(time) {
        timeElapsed = time - timeStart;
        window.scrollTo(0, opt.easing(timeElapsed, start, distance, duration));
        if (timeElapsed < duration) {
            requestAnimationFrame(loop);
        } else
            end();
    }

    function end() {
        window.scrollTo(0, start + distance);
        if (typeof opt.callback === 'function') 
        opt.callback();
    }

    function easeInOutQuad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t * t + b;
        t -= 2;
        return c / 2 * (t * t * t + 2) + b;
    }
}